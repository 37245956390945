import {
    SETLOADING,
    SETDATA,
	SETKEYWORDS,
   RELOADTABLE,
   FETCHDATA,
   SETTABLESTATE,
   SETSHOWUPDATEREPORTMODAL,
   SETCURRENTREPORTUPDATE,
   SETMODALFORMLOADING,
   SETFORMERROR,
   SETSHOWCREATEREPORTMODAL,
   RESETFORM,
   SETCATEGORIES,
   SETDISTRIBUIDORES,
   SETSELECTEDCATEGORY,
   SETSELECTEDDISTRIBUIDOR,
   SETSTARTDATE,
   SETENDDATE,
   SETSHOWREPORTMODAL,
   SETCURRENTREPORTSHOW,
   SETSELECTEDROWS
} from '../constants/Reports';


const initState = {
  loading: false,
  data: [],
  keywords:"",
  reloadTable:false,
  tableState:{ pagination:null,	filters:null,sorter:null,},
  currentReportUpdate:null ,
  showUpdatReportModal:false,
  showCreateReportModal:false,
  modalFormLoading:false,
  formError:null,
  categories:null,
  distribuidores:null,
  selectedCategory:null,
  selectedDistribuidor:null,
  startDate:null,
  endDate:null,
  showReportModal:false,
  currentReportShow:null,
  selectedRows:[],
  newReporUUID:null
}

const reports = (state = initState, action) => {
	//console.log(action);
	switch (action.type) {
		case SETSELECTEDCATEGORY:
			return {
				...state,
				selectedCategory:action.category,

			}	
	    case SETSELECTEDROWS:
			return {
				...state,
				selectedRows:action.rows,

			}
			case SETSELECTEDDISTRIBUIDOR:
				return {
					...state,
					selectedDistribuidor:action.distribuidor,
	
				}

	    	case SETSTARTDATE:
					return {
						...state,
						startDate:action.startDate,
		
					}
			case SETENDDATE:
						return {
							...state,
							endDate:action.endDate,
			
						}
								
		case SETCATEGORIES:
			return {
				...state,
				categories:action.categories,

			}
			case SETDISTRIBUIDORES:
			return {
				...state,
				distribuidores:action.distribuidores,

			}
		case SETSHOWUPDATEREPORTMODAL:
			return {
				...state,
				showUpdateCategoruModal:action.show,

			}

			case SETSHOWREPORTMODAL:
				return {
					...state,
					showReportModal:action.show,
	
				}
				case SETCURRENTREPORTSHOW:
					return {
						...state,
						currentReportShow:action.report,
						showReportModal:true
					}
		case RESETFORM:
	    	return {
				...state,
				formError:null,
				modalFormLoading:false,
				currentReportUpdate:null ,

			}
		case SETSHOWCREATEREPORTMODAL:
				return {
					...state,
					showCreateReportModal:action.show,
					newReporUUID:Date.now().toString(36) + Math.random().toString(36).substring(2),
	
				}
	   case SETFORMERROR:
			return {
				...state,
				formError:action.formError,

			}
    	case SETMODALFORMLOADING:
				return {
					...state,
					modalFormLoading:action.modalFormLoading,
	
				}
	
	    case SETCURRENTREPORTUPDATE:
			return {
				...state,
				currentReportUpdate: action.report,
				showUpdateReportModal:true
			}
		case SETLOADING:
			return {
				...state,
				loading: action.loading
			}
		case RELOADTABLE:
			return {
				...state,
				reloadTable: true
			}
		case SETTABLESTATE:
			return {
				...state,
				tableState: { pagination: action.pagination,filters:action.filters,sorter:action.sorter}
				
			}
		case FETCHDATA:
			return {
				...state,
				reloadTable: false
			}
    	case SETDATA:
			return {
				...state,
				data: action.data
			}
			case SETKEYWORDS:
				return {
					...state,
					keywords: action.keywords
				}
		default:
			return state;
	}
}

export default reports