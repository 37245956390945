import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK,
	RESET_PASSWORD_STEP1,
	RESET_PASSWORD_STEP2,	
	GETUSER,
	GETCATEGORIES
} from '../constants/Auth';
import {
	userSuccess,
	setCategories,
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	resetPasswordSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated
} from "../actions/Auth";

import FirebaseService from 'services/FirebaseService'
import JwtAuthService from 'services/JwtAuthService'
import CategoryService from 'services/admin/CategoryService'

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload,redirect}) {	  	
		try {
			const user = yield call(JwtAuthService.login, payload);
            
			if (user.error) {
				yield put(showAuthMessage(user.error));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.access_token);
				if(redirect === "/auth/login"){
					yield put(authenticated(user.access_token,user.user,null));
				}else{
			    	yield put(authenticated(user.access_token,user.user,redirect));
				}
			}
		} catch (err) {
			
			yield put(showAuthMessage('Error de comunicación'));
		}
	});
}

export function* resetPasswordStep1() {
	yield takeEvery(RESET_PASSWORD_STEP1, function* ({payload}) {	 		
		  try {
			  const data = yield call(JwtAuthService.resetPasswordStep1, payload);
            //  console.log(data);
			  if (data.status) {			
				  yield put(showAuthMessage(data.message,true));
			  } else {					
				yield put(showAuthMessage(data.message,false));	 
			  }
		  } catch (err) {
			yield put(showAuthMessage('Error de comunicación'));
		  }
	  });
  }

  export function* resetPasswordStep2() {
	yield takeEvery(RESET_PASSWORD_STEP2, function* ({payload,token,email}) {	 	 
		  try {
			  const data = yield call(JwtAuthService.resetPasswordStep2, payload,token,email);         
			  if (data.status) {			
				  yield put( resetPasswordSuccess(data.message));
			  } else {					
				yield put(showAuthMessage(data.message,false));	 
			  }
		  } catch (err) {
		    	yield put(showAuthMessage('Error de comunicación'));
		  }
		
		 
	  });
  }


export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(JwtAuthService.logout);
				localStorage.removeItem(AUTH_TOKEN);
			if (signOutUser.message) {
				yield put(signOutSuccess(signOutUser.message));
			} 
		} catch (err) {
			localStorage.removeItem(AUTH_TOKEN);
			yield put(signOutSuccess(''));	
		}
	});
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(FirebaseService.signUpEmailRequest, email, password);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signUpSuccess(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* getUser() {
	yield takeEvery(GETUSER, function* () {
		try {
			const user = yield call(JwtAuthService.me);
			yield put(userSuccess(user));
			
		} catch (err) {
		//	yield put(showAuthMessage(err));
		}
	  });
  }

  
  export function* getCategories() {
	yield takeEvery(GETCATEGORIES, function* () {
		try {
			const data = yield call(CategoryService.index);
			yield put(setCategories(data.data));	
		} catch (err) {
		//	yield put(showAuthMessage(err));
		}
	  });
  }

export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook),
		fork(resetPasswordStep1),
		fork(resetPasswordStep2),
		fork(getCategories),
		fork(getUser),

  ]);
}
