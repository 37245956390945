import fetch from 'auth/FetchInterceptor'

const rolesService = {}

rolesService.index = function (data) {
   return fetch({
        url: '/admin/roles',
        method: 'get',
        headers: {
          'public-request': 'false'
        },
        params:data});      
}


export default rolesService