import {
	SET_LOADING,
	SHOW_ERROR,
	SHOW_MESSAGE,
	CLEAR_MESSAGE
} from '../constants/ProfileConfig';

const initState = {
  loading: false,
  message: null,
  error: null

}

const ProfileConfig = (state = initState, action) => {
	switch (action.type) {
		case SET_LOADING: {	       
			return {
				...state,	
				loading: action.loading,
			}
		}
		case SHOW_ERROR: {	       
			return {
				...state,	
				error: action.error,
			}
		}
		case SHOW_MESSAGE: {	       
			return {
				...state,	
				message: action.message,
			}
		}

		case CLEAR_MESSAGE:{
			return {
				...state,	
				message: null,
				error:null
			}
		}

		default:
			return state;
	}
}

export default ProfileConfig