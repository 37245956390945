import { combineReducers } from 'redux';
import Users  from './Users';
import Categories  from './Categories';
import Distribuidores  from './Distribuidores';

const reducers = combineReducers({
    users: Users,
    categories: Categories,
    distribuidores: Distribuidores
});

export default reducers;