import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Reports from './Reports';
import ProfileConfig from './ProfileConfig';
import AdminReducers  from './admin';

const reducers = combineReducers({
    admin: AdminReducers,
    theme: Theme,
    auth: Auth,
    ProfileConfig: ProfileConfig,
    reports: Reports
   
});

export default reducers;