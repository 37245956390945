import {
    SETLOADING,
    SETDATA,
	SETKEYWORDS,
   RELOADTABLE,
   FETCHDATA,
   SETTABLESTATE,
   SETSHOWUPDATECATEGORYMODAL,
   SETCURRENTCATEGORYUPDATE,
   SETMODALFORMLOADING,
   SETFORMERROR,
   SETSHOWCREATECATEGORYMODAL,
   RESETFORM
} from '../../constants/admin/Categories';


const initState = {
  loading: false,
  data: [],
  keywords:"",
  reloadTable:false,
  tableState:{ pagination:null,	filters:null,sorter:null,},
  currentCategoryUpdate:null ,
  showUpdatCategoryModal:false,
  showCreateCategoryModal:false,
  modalFormLoading:false,
  formError:null,

}

const categories = (state = initState, action) => {
	switch (action.type) {
		case SETSHOWUPDATECATEGORYMODAL:
			return {
				...state,
				showUpdateCategoruModal:action.show,

			}
		case RESETFORM:
	    	return {
				...state,
				formError:null,
				modalFormLoading:false,
				currentCategoryUpdate:null ,

			}
		case SETSHOWCREATECATEGORYMODAL:
				return {
					...state,
					showCreateCategoryModal:action.show,
	
				}
	   case SETFORMERROR:
			return {
				...state,
				formError:action.formError,

			}
    	case SETMODALFORMLOADING:
				return {
					...state,
					modalFormLoading:action.modalFormLoading,
	
				}
	
	    case SETCURRENTCATEGORYUPDATE:
			return {
				...state,
				currentCategoryUpdate: action.category,
				showUpdateCategoryModal:true
			}
		case SETLOADING:
			return {
				...state,
				loading: action.loading
			}
		case RELOADTABLE:
			return {
				...state,
				reloadTable: true
			}
		case SETTABLESTATE:
			return {
				...state,
				tableState: { pagination: action.pagination,filters:action.filters,sorter:action.sorter}
				
			}
		case FETCHDATA:
			return {
				...state,
				reloadTable: false
			}
    	case SETDATA:
			return {
				...state,
				data: action.data
			}
			case SETKEYWORDS:
				return {
					...state,
					keywords: action.keywords
				}
		default:
			return state;
	}
}

export default categories