import fetch from 'auth/FetchInterceptor'
const CategoryService = {}

CategoryService.index = function (data) {
   return fetch({
        url: '/admin/categories',
        method: 'get',
        headers: {
          'public-request': 'false'
        },
        params:data});       
}


CategoryService.getCategory = function (categoryId) {

  return fetch({
       url: '/admin/categories/'+categoryId,
       method: 'get',
       headers: {
         'public-request': 'false'
       },
     });
       
}

CategoryService.delete = function (categorieId) {
  return fetch({
       url: '/admin/categories/'+categorieId,
       method: 'delete',
       headers: {
         'public-request': 'false'
       }});     
}

CategoryService.update = function (categorieId,data) {
	return fetch({
		url: '/admin/categories/'+categorieId,
		method: 'put',
		headers: {
      'public-request': 'false'
    },
		data: data
	})
}

CategoryService.uploadFile = function (config,categoryId,data) {
	return fetch({
		url: '/admin/categories/uploadFile/'+categoryId,
		method: 'post',
		headers: {
      'public-request': 'false'
    }['public-request'],
		data: data,
		...config
	})
}

CategoryService.deleteFile = function (categoryId,fileId) {
  return fetch({
		url: '/admin/categories/'+categoryId+"/uploadFile/"+fileId,
		method: 'delete',
		headers: {
      'public-request': 'false'
    },
	
	})
}

CategoryService.create = function (data) {
	return fetch({
		url: '/admin/categories',
		method: 'post',
		headers: {
      'public-request': 'false'
    },
		data: data
	})
}

export default CategoryService