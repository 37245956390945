import React, { useEffect } from 'react'; 
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import { 	getUser,getCategories } from 'redux/actions/Auth';

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
} 
export const Views = (props) => {
  const { token,locale, location, direction,user,
    getUser,categories, getCategories} = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);


  useEffect(() => {
    if(token && !user){
      getUser();
    }

    if(token && !categories){
        getCategories();
    }
  
	},[token,user,getUser,categories,getCategories]);
	
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location}/>
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } =  theme;
  const { token,user,categories } = auth;
  return { user,locale, token, direction,categories }
};

const mapDispatchToProps = {
	getUser,
  getCategories,
	
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Views));