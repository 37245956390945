export const SIGNIN = 'SIGNIN'; 
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const AUTH_TOKEN = 'auth_token'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'
export const RESET_PASSWORD_STEP1 = 'RESET_PASSWORD_STEP1'
export const RESET_PASSWORD_STEP2 = 'RESET_PASSWORD_STEP2'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const USER_SUCCESS = 'USER_SUCCESS'
export const GETUSER = 'GETUSER'
export const GETCATEGORIES = 'GETCATEGORIES'
export const SETCATEGORIES = 'SETCATEGORIES'