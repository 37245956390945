import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { notification } from 'antd';
import {
    FETCHDATA,
    USERDELETE,
    FETCHROLES,
    USERUPDATE,
    USERCREATE,
    FETCHDISTRIBUIDORES,
    RELOADCURRENTUSERUPDATE
} from '../../constants/admin/Users';

import {
    setLoading,
    setData,
    reloadTable,
    setModalFormLoading,
    setShowUpdateUserModal,
    setShowCreateUserModal,
    setRoles,
    setFormError,
    setDistribuidores,
    setCurrentUserUpdate,

} from "../../actions/admin/Users";

import UserService from 'services/admin/UserService'

import RolesService from 'services/admin/RolesService'

import DistribuidoresService from 'services/admin/DistribuidorService'

export function* fetchDataa() {
    yield takeEvery(FETCHDATA, function* ({payload}) {

         yield put(setLoading(true));
         const data = yield call(UserService.index, payload);

         var dataTosave = {
           data : data.data,
           pagination : {
             current: data.current_page,
             total : data.total,
             pageSize: data.per_page,
             showSizeChanger : false

           }
         }



        yield put(setData(dataTosave));
        yield put(setLoading(false));
      });

}

export function* userDelete() {
  yield takeEvery(USERDELETE, function* ({userId}) {



       const data = yield call(UserService.delete, userId);
       if(data.status){
          notification.success({message:data.message});
          yield put(reloadTable());
       }else{
          notification.error({message:data.message});
        }


     // yield put(setData(dataTosave));
    //  yield put(setLoading(false));
    });

}

export function* userUpdate() {
  yield takeEvery(USERUPDATE, function* ({payload,userId}) {

     try{
        const data = yield call(UserService.update,userId,payload);
        if(data.status){
            notification.success({message:data.message});
            yield put(setModalFormLoading(false));
            yield put(setShowUpdateUserModal(false));
            yield put(reloadTable());
        }else{
            notification.error({message:data.message});
          }
      } catch (err) {
           yield put(setFormError(err.errors));
           yield put(setModalFormLoading(false));
        }

     // yield put(setData(dataTosave));
    //  yield put(setLoading(false));
    });

}


export function* userCreate() {
  yield takeEvery(USERCREATE, function* ({payload,userId}) {	  	
    
     try{
        const data = yield call(UserService.create,payload);
        if(data.status){
            notification.success({message:data.message});
            yield put(setModalFormLoading(false));
            yield put(setShowCreateUserModal(false));
            yield put(reloadTable());     
        }else{
            notification.error({message:data.message});
          }
      } catch (err) {
           yield put(setFormError(err.errors));
           yield put(setModalFormLoading(false));
        }
    
     // yield put(setData(dataTosave));
    //  yield put(setLoading(false));     
    });

}

export function* fetchRoles() {
  yield takeEvery(FETCHROLES, function* ({payload}) {


       const data = yield call(RolesService.index, payload);
       if(data.status){
          yield put(setRoles(data.data));
       }
    });

}

export function* fetchDistribuidores() {
  yield takeEvery(FETCHDISTRIBUIDORES, function* ({payload}) {

       const data = yield call(DistribuidoresService.index, payload);
       
          yield put(setDistribuidores(data.data));
       
    });

}

export function* reloadCurrentUserUpdate() {
  yield takeEvery(RELOADCURRENTUSERUPDATE, function* ({id}) {	  	
       const data = yield call(UserService.getUser, id);
       yield put( setCurrentUserUpdate(data));
     
    });

}

export default function* rootSaga() {
  yield all([
      fork(fetchDataa),
      fork(userDelete),
      fork(fetchRoles),
      fork(userUpdate),
      fork(userCreate),
      fork(fetchDistribuidores),
      fork(reloadCurrentUserUpdate),

  ]);
};
