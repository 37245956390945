import {
    SETLOADING,
    SETDATA,
    FETCHDATA,
    SETKEYWORDS,
    USERDELETE,
    RELOADTABLE,
    SETTABLESTATE,
    SETSHOWUPDATEUSERMODAL,
    SETCURRENTUSERUPDATE,
    SETROLES,
    FETCHROLES,
    USERUPDATE,
    SETMODALFORMLOADING,
    SETFORMERROR,
    SETSHOWCREATEUSERMODAL,
    RESETFORM,
    USERCREATE,
    FETCHDISTRIBUIDORES,
    SETDISTRIBUIDORES,
    SETDISTRIBUIDORESSHOWSELECT,

    RELOADCURRENTUSERUPDATE
  } from '../../constants/admin/Users';

  
  export const reloadCurrentUserUpdate= (id) => {
    return {
      type: RELOADCURRENTUSERUPDATE,
      id
  
    }
  };


  export const setCurrentUserUpdate= (user) => {
    return {
      type: SETCURRENTUSERUPDATE,
      user
  
    }
  };

  export const setDistribuidoresShowSelect= (show) => {
    return {
      type: SETDISTRIBUIDORESSHOWSELECT,
      show
  
    }
  };

  
  export const resetForm= () => {
    return {
      type: RESETFORM,
  
    }
  };

  export const setShowUpdateUserModal= (show) => {
    return {
      type: SETSHOWUPDATEUSERMODAL,
      show
  
    }
  };

  export const setShowCreateUserModal= (show) => {
    return {
      type: SETSHOWCREATEUSERMODAL,
      show
  
    }
  };

  export const setFormError = (formError) => {
    return {
      type: SETFORMERROR,
      formError
  
    }
  };

  
  export const setRoles= (roles) => {
    return {
      type: SETROLES,
      roles
  
    }
  };


  export const fetchRoles= (payload) => {
    return {
      type: FETCHROLES,
      payload
  
    }
  };

  export const fetchDistribuidores= (payload) => {
    
    return {
      type: FETCHDISTRIBUIDORES,
      payload
  
    }
  };

  export const setDistribuidores= (distribuidores) => {
    return {
      type: SETDISTRIBUIDORES,
      distribuidores
  
    }
  };


  export const setLoading = (loading) => {
    return {
      type: SETLOADING,
      loading
  
    }
  };
  export const setKeywords = (keywords) => {
    return {
      type: SETKEYWORDS,
      keywords
  
    }
  };

  export const setData = (data) => {
    return {
      type: SETDATA,
      data
  
    }
  };
  export const setModalFormLoading = (modalFormLoading) => {
    return {
      type: SETMODALFORMLOADING,
      modalFormLoading

  
    }
  };
  export const fetchData = (payload) => { 
     
    return {
      type: FETCHDATA,
      payload
    }
  };

  export const reloadTable = () => { 
     
    return {
      type: RELOADTABLE,
      
    }
  };


  export const userDelete = (userId) => { 
     
    return {
      type: USERDELETE,
      userId
    }
  };


  export const userUpdate = (userId,payload) => { 
     
    return {
      type: USERUPDATE,
      payload,
      userId
    }
  };

  export const userCreate = (payload) => { 
     
    return {
      type: USERCREATE,
      payload,
      
    }
  };
  
  export const setTableState = (pagination,filters,sorter)=>{
    return {
      type: SETTABLESTATE,
      pagination,
      filters,
      sorter,
    }

  }