import {
    SETLOADING,
    SETDATA,
	SETKEYWORDS,
   RELOADTABLE,
   FETCHDATA,
   SETTABLESTATE,
   SETSHOWUPDATEUSERMODAL,
   SETCURRENTUSERUPDATE,
   SETROLES,
   SETMODALFORMLOADING,
   SETFORMERROR,
   SETSHOWCREATEUSERMODAL,
   RESETFORM,
   SETDISTRIBUIDORES,
   SETDISTRIBUIDORESSHOWSELECT
} from '../../constants/admin/Users';


const initState = {
  loading: false,
  data: [],
  keywords:"",
  reloadTable:false,
  tableState:{ pagination:null,	filters:null,sorter:null,},
  currentUserUpdate:null ,
  showUpdateUserModal:false,
  showCreateUserModal:false,
  modalFormLoading:false,
  formError:null,
  roles:null,
  distribuidores:null,
  showDistribuidoresSelect:false,
  
}

const users = (state = initState, action) => {

	switch (action.type) {
		case SETSHOWUPDATEUSERMODAL:
			return {
				...state,
				showUpdateUserModal:action.show,

			}
		case SETDISTRIBUIDORESSHOWSELECT:
				return {
					...state,
					showDistribuidoresSelect:action.show,
	
				}
		case RESETFORM:
	    	return {
				...state,
				formError:null,
				modalFormLoading:false,
				currentUserUpdate:null ,
				showDistribuidoresSelect:false

			}
		case SETSHOWCREATEUSERMODAL:
				return {
					...state,
					showCreateUserModal:action.show,
	
				}
	   case SETFORMERROR:
			return {
				...state,
				formError:action.formError,

			}
    	case SETMODALFORMLOADING:
				return {
					...state,
					modalFormLoading:action.modalFormLoading,
	
				}
		case SETROLES:
				return {
					...state,
					roles:action.roles,
				}
	    case SETDISTRIBUIDORES:
				return {
						...state,
				    	distribuidores:action.distribuidores,
					}
	    case SETCURRENTUSERUPDATE:
			return {
				...state,
				currentUserUpdate: action.user,
				showDistribuidoresSelect:(action.user.role_permissions.role==="encargado" || action.user.role_permissions.role==="funcionario"),
				showUpdateUserModal:true,
				
			}
		case SETLOADING:
			return {
				...state,
				loading: action.loading
			}
		case RELOADTABLE:
			return {
				...state,
				reloadTable: true
			}
		case SETTABLESTATE:
			return {
				...state,
				tableState: { pagination: action.pagination,filters:action.filters,sorter:action.sorter}
				
			}
		case FETCHDATA:
			return {
				...state,
				reloadTable: false
			}
    	case SETDATA:
			return {
				...state,
				data: action.data
			}
			case SETKEYWORDS:
				return {
					...state,
					keywords: action.keywords
				}
		default:
			return state;
	}
}

export default users