import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { notification } from 'antd';
import {
    FETCHDATA,
    REPORTDELETE,
    REPORTUPDATE,
    REPORTCREATE,
    FETCHCATEGORIES,
    FETCHDISTRIBUIDORES
} from '../constants/Reports';

import {
    setLoading,
    setData,
    reloadTable,
    setModalFormLoading,
    setShowUpdateReportModal,
    setShowCreateReportModal,
    setFormError,
    setDistribuidores,
    setCategories
} from "../actions/Reports";

import ReportService from 'services/ReportService'
import DistribuidoresService from 'services/admin/DistribuidorService'
import CategoryService from 'services/admin/CategoryService'



export function* fetchDataa() {
    yield takeEvery(FETCHDATA, function* ({payload}) {
       
         yield put(setLoading(true));
         const data = yield call(ReportService.index, payload);

         var dataTosave = {
             data : data.data,
             pagination : {
             current: data.current_page,
             total : data.total,
             pageSize: data.per_page,
             showSizeChanger : false

           }
         }



        yield put(setData(dataTosave));
        yield put(setLoading(false));
      });

}

export function* ReportDelete() {
  yield takeEvery(REPORTDELETE, function* ({reportId}) {



       const data = yield call(ReportService.delete, reportId);
       if(data.status){
          notification.success({message:data.message});
          yield put(reloadTable());
       }else{
          notification.error({message:data.message});
        }


     // yield put(setData(dataTosave));
    //  yield put(setLoading(false));
    });

}

export function* ReportUpdate() {
  yield takeEvery(REPORTUPDATE, function* ({payload,reportId}) {

     try{
        const data = yield call(ReportService.update,reportId,payload);
        if(data.status){
            notification.success({message:data.message});
            yield put(setModalFormLoading(false));
            yield put(setShowUpdateReportModal(false));
            yield put(reloadTable());
        }else{
            notification.error({message:data.message});
          }
      } catch (err) {
           yield put(setFormError(err.errors));
           yield put(setModalFormLoading(false));
        }

     // yield put(setData(dataTosave));
    //  yield put(setLoading(false));
    });

}


export function* ReportCreate() {
  yield takeEvery(REPORTCREATE, function* ({categoryId,payload}) {	  	
    
     try{
        const data = yield call(ReportService.create,categoryId,payload);
        if(data.status){
            notification.success({message:data.message});
            yield put(setModalFormLoading(false));
            yield put(setShowCreateReportModal(false));
            yield put(reloadTable());     
        }else{
            notification.error({message:data.message});
          }
      } catch (err) {
           yield put(setFormError(err.errors));
           yield put(setModalFormLoading(false));
        }
    
     // yield put(setData(dataTosave));
    //  yield put(setLoading(false));     
    });

}

export function* fetchDistribuidores() {
  yield takeEvery(FETCHDISTRIBUIDORES, function* ({payload}) {

       const data = yield call(DistribuidoresService.index, payload);
       
          yield put(setDistribuidores(data.data));
       
    });

}

export function* fetchCategories() {
  yield takeEvery(FETCHCATEGORIES, function* ({payload}) {

       const data = yield call(CategoryService.index, payload);
       
          yield put(setCategories(data.data));
       
    });

}

export default function* rootSaga() {
  yield all([
      fork(fetchDataa),
      fork(ReportDelete),
      fork(ReportUpdate),
      fork(ReportCreate),
      fork(fetchCategories),
      fork(fetchDistribuidores),

  ]);
};
