import {
    SETLOADING,
    SETDATA,
    FETCHDATA,
    SETKEYWORDS,
    CATEGORYDELETE,
    RELOADTABLE,
    SETTABLESTATE,
    SETSHOWUPDATECATEGORYMODAL,
    SETCURRENTCATEGORYUPDATE,
    CATEGORYUPDATE,
    SETMODALFORMLOADING,
    SETFORMERROR,
    SETSHOWCREATECATEGORYMODAL,
    RESETFORM,
    CATEGORYCREATE,
    RELOADCURRENTCATEGORYUPDATE
  } from '../../constants/admin/Categories';

  export const reloadCurrentCategoryUpdate= (id) => {
    return {
      type: RELOADCURRENTCATEGORYUPDATE,
      id
  
    }
  };


  export const setCurrentCategoryUpdate= (category) => {
    return {
      type: SETCURRENTCATEGORYUPDATE,
      category
  
    }
  };

  
  export const resetForm= () => {
    return {
      type: RESETFORM,
  
    }
  };

  export const setShowUpdateCategoryModal= (show) => {
    return {
      type: SETSHOWUPDATECATEGORYMODAL,
      show
  
    }
  };

  export const setShowCreateCategoryModal= (show) => {
    return {
      type: SETSHOWCREATECATEGORYMODAL,
      show
  
    }
  };

  export const setFormError = (formError) => {
    return {
      type: SETFORMERROR,
      formError
  
    }
  };

  





  export const setLoading = (loading) => {
    return {
      type: SETLOADING,
      loading
  
    }
  };
  export const setKeywords = (keywords) => {
    return {
      type: SETKEYWORDS,
      keywords
  
    }
  };

  export const setData = (data) => {
    return {
      type: SETDATA,
      data
  
    }
  };
  export const setModalFormLoading = (modalFormLoading) => {
    return {
      type: SETMODALFORMLOADING,
      modalFormLoading

  
    }
  };
  export const fetchData = (payload) => { 
     
    return {
      type: FETCHDATA,
      payload
    }
  };

  export const reloadTable = () => { 
     
    return {
      type: RELOADTABLE,
      
    }
  };


  export const categoryDelete = (categoryId) => { 
     
    return {
      type: CATEGORYDELETE,
      categoryId
    }
  };


  export const categoryUpdate = (categoryId,payload) => { 
     
    return {
      type: CATEGORYUPDATE,
      payload,
      categoryId
    }
  };

  export const categoryCreate = (payload) => { 
     
    return {
      type: CATEGORYCREATE,
      payload,
      
    }
  };
  
  export const setTableState = (pagination,filters,sorter)=>{
    return {
      type: SETTABLESTATE,
      pagination,
      filters,
      sorter,
    }

  }