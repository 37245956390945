import {
    SETLOADING,
    SETDATA,
    FETCHDATA,
    SETKEYWORDS,
    REPORTDELETE,
    RELOADTABLE,
    SETTABLESTATE,
    SETSHOWUPDATEREPORTMODAL,
    SETCURRENTREPORTUPDATE,
    REPORTUPDATE,
    SETMODALFORMLOADING,
    SETFORMERROR,
    SETSHOWCREATEREPORTMODAL,
    RESETFORM,
    REPORTCREATE,
    FETCHCATEGORIES,
    FETCHDISTRIBUIDORES,
    SETDISTRIBUIDORES,
    SETCATEGORIES,
    SETSELECTEDCATEGORY,
    SETSELECTEDDISTRIBUIDOR,
    SETSTARTDATE,
    SETENDDATE,
    SETSHOWREPORTMODAL,
    SETCURRENTREPORTSHOW,
    SETSELECTEDROWS

  } from '../constants/Reports';

  export const setSelectedRows= (rows) => {
    return {
      type: SETSELECTEDROWS,
      rows
  
    }
  };

  export const setCurrentReportShow= (report) => {
    return {
      type: SETCURRENTREPORTSHOW,
      report
  
    }
  };
  
  export const setShowReportModal= (show) => {
    return {
      type: SETSHOWREPORTMODAL,
      show
  
    }
  };

  export const setCurrentReportUpdate= (report) => {
    return {
      type: SETCURRENTREPORTUPDATE,
      report
  
    }
  };

  
  export const resetForm= () => {
    return {
      type: RESETFORM,
  
    }
  };

  export const setShowUpdateReportModal= (show) => {
    return {
      type: SETSHOWUPDATEREPORTMODAL,
      show
  
    }
  };

  export const setShowCreateReportModal= (show) => {
    return {
      type: SETSHOWCREATEREPORTMODAL,
      show
  
    }
  };

  export const setFormError = (formError) => {
    return {
      type: SETFORMERROR,
      formError
  
    }
  };

  





  export const setLoading = (loading) => {
    return {
      type: SETLOADING,
      loading
  
    }
  };
  export const setKeywords = (keywords) => {
    return {
      type: SETKEYWORDS,
      keywords
  
    }
  };

  export const setData = (data) => {
    return {
      type: SETDATA,
      data
  
    }
  };
  export const setModalFormLoading = (modalFormLoading) => {
    return {
      type: SETMODALFORMLOADING,
      modalFormLoading

  
    }
  };
  export const fetchData = (payload) => { 
    
    return {
      type: FETCHDATA,
      payload
    }
  };

  export const reloadTable = () => { 
     
    return {
      type: RELOADTABLE,
      
    }
  };


  export const reportDelete = (reportId) => { 
     
    return {
      type: REPORTDELETE,
      reportId
    }
  };


  export const reportUpdate = (reportId,payload) => { 
     
    return {
      type: REPORTUPDATE,
      payload,
      reportId
    }
  };

  export const reportCreate = (categoryId,payload) => { 
     
    return {
      type: REPORTCREATE,
      payload,
      categoryId
    }
  };
  
  export const setTableState = (pagination,filters,sorter)=>{
    return {
      type: SETTABLESTATE,
      pagination,
      filters,
      sorter,
    }

  }

  export const fetchDistribuidores= (payload) => {
    
    return {
      type: FETCHDISTRIBUIDORES,
      payload
  
    }
  };

  export const setDistribuidores= (distribuidores) => {
    return {
      type: SETDISTRIBUIDORES,
      distribuidores
  
    }
  };

  export const fetchCategories= (payload) => {
    
    return {
      type: FETCHCATEGORIES,
      payload
  
    }
  };

  export const setCategories= (categories) => {
    return {
      type: SETCATEGORIES,
      categories
  
    }
  };


  export const setSelectedCategory= (category) => {
    return {
      type: SETSELECTEDCATEGORY,
      category
  
    }
  };
  
  export const setSelectedDistribuidor= (distribuidor) => {
    return {
      type: SETSELECTEDDISTRIBUIDOR,
      distribuidor
  
    }
  };
  
  export const setStartDate= (startDate) => {
    return {
      type: SETSTARTDATE,
      startDate
  
    }
  };
  
  export const setEndDate= (endDate) => {
    return {
      type: SETENDDATE,
      endDate
  
    }
  };
  