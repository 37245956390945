export const SETLOADING = 'SETLOADING_DISTRIBUIDORES'
export const SETDATA = 'SETDATA_DISTRIBUIDORES'
export const SETKEYWORDS = 'SETKEYWORDS_DISTRIBUIDORES'
export const FETCHDATA = 'FETCHDATA_DISTRIBUIDORES'
export const DISTRIBUIDORDELETE = 'DISTRIBUIDORDELETE_DISTRIBUIDORES'
export const DISTRIBUIDORUPDATE = 'DISTRIBUIDORUPDATE_DISTRIBUIDORES'
export const DISTRIBUIDORCREATE = 'DISTRIBUIDORCREATE_DISTRIBUIDORES'
export const RELOADTABLE = 'RELOADTABLE_DISTRIBUIDORES'
export const SETTABLESTATE = 'SETTABLESTATE_DISTRIBUIDORES'

export const SETMODALFORMLOADING = 'SETMODALFORMLOADING_DISTRIBUIDORES'
export const SETFORMERROR = 'SETFORMERROR__DISTRIBUIDORES'
export const RESETFORM = 'RESETFORM__DISTRIBUIDORES'

export const SETSHOWUPDATEDISTRIBUIDORMODAL = 'SETSHOWUPDATEDISTRIBUIDORMODAL_DISTRIBUIDORES'
export const SETSHOWCREATEDISTRIBUIDORMODAL = 'SETSHOWCREATEDISTRIBUIDORMODAL_DISTRIBUIDORES'
export const SETCURRENTDISTRIBUIDORUPDATE = 'SETCURRENTDISTRIBUIDORUPDATE_DISTRIBUIDORES'

