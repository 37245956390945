import {
  CHANGE_PASSWORD,
  SET_LOADING,
  SHOW_ERROR,
  SHOW_MESSAGE,
  CLEAR_MESSAGE,
  UPDATE_PROFILE
} from '../constants/ProfileConfig';

export const ChangePasswordReq = (data) => {
  return {
    type: CHANGE_PASSWORD,
    payload: data
  }
};

export const updateProfileReq = (data) => {
  return {
    type: UPDATE_PROFILE,
    payload: data
  }
};


export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    message: message
  }
};

export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,

  }
};
export const showError = (error) => {
  return {
    type: SHOW_ERROR,
    error: error
  }
};

export const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    loading: loading
  }
};
