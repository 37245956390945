import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import { 
  SettingOutlined, 
  LogoutOutlined ,
  UserOutlined,

	LockOutlined, 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { Link } from 'react-router-dom';

const menuItem = [
  {
		title: "Perfil",
		icon: UserOutlined,
		path: "/app/settings/profile"
    },
    {
      title: "Cambiar contraseña",
      icon: LockOutlined,
      path: "/app/settings/change-password"
      },

    {
		title: "Configuración",
		icon: SettingOutlined,
		path: "/app/settings"
    },
  
]

export const NavProfile = ({signOut,user}) => {

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">          
          <div className="pl-3">
            <h4 className="mb-0">{user.name}</h4>
            <span className="text-muted">{user.email}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                  <Link to={el.path}/>
                
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Cerrar sesión</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar style={{ backgroundColor: '#205C40' }} src={user.avatar_url} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

const mapStateToProps = ({auth }) => {

  const { user } = auth;
  return { user }
};


export default connect(mapStateToProps, {signOut})(NavProfile)
