import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect,withRouter } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { connect } from "react-redux";

export const AppViews = () => {

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/cat/:id/:name`} component={lazy(() => import(`./Category`))} />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Route path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./report`))} />
        <Route path={`${APP_PREFIX_PATH}/admin/users`} component={lazy(() => import(`./admin/users`))} />
        <Route path={`${APP_PREFIX_PATH}/admin/visualizaciones`} component={lazy(() => import(`./admin/visualizaciones`))} />
        <Route path={`${APP_PREFIX_PATH}/admin/categories`} component={lazy(() => import(`./admin/categories`))} />
        <Route path={`${APP_PREFIX_PATH}/admin/distribuidores`} component={lazy(() => import(`./admin/distribuidores`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}


export default withRouter(connect()(AppViews));