export const SETLOADING = 'SETLOADING_CATEGORIES'
export const SETDATA = 'SETDATA_CATEGORIES'
export const SETKEYWORDS = 'SETKEYWORDS_CATEGORIES'
export const FETCHDATA = 'FETCHDATA_CATEGORIES'
export const CATEGORYDELETE = 'CATEGORYDELETE_CATEGORIES'
export const CATEGORYUPDATE = 'CATEGORYUPDATE_CATEGORIES'
export const CATEGORYCREATE = 'CATEGORYCREATE_CATEGORIES'
export const RELOADTABLE = 'RELOADTABLE_CATEGORIES'
export const SETTABLESTATE = 'SETTABLESTATE_CATEGORIES'

export const SETMODALFORMLOADING = 'SETMODALFORMLOADING_CATEGORIES'
export const SETFORMERROR = 'SETFORMERROR_CATEGORIES'
export const RESETFORM = 'RESETFORM_CATEGORIES'

export const SETSHOWUPDATECATEGORYMODAL = 'SETSHOWUPDATECATEGORYMODAL_CATEGORIES'
export const SETSHOWCREATECATEGORYMODAL = 'SETSHOWCREATECATEGORYMODAL_CATEGORIES'
export const SETCURRENTCATEGORYUPDATE = 'SETCURRENTCATEGORYUPDATE_CATEGORIES'
export const RELOADCURRENTCATEGORYUPDATE = 'RELOADCURRENTCATEGORYUPDATE_CATEGORIES'
