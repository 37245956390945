import fetch from 'auth/FetchInterceptor'

const ProfileConfigService = {}

ProfileConfigService.changePassword = function (data) {
	return fetch({
		url: '/config/profile/change-password',
		method: 'post',
		headers: {
      'public-request': 'false'
    },
		data: data
	})
}

ProfileConfigService.updateProfile = function (data) {
	return fetch({
		url: '/config/profile/update-profile',
		method: 'post',
		headers: {
      'public-request': 'false'
    },
		data: data
	})
}



ProfileConfigService.updateImage = function (config,data) {
	return fetch({
		url: '/config/profile/update-profile-image',
		method: 'post',
		headers: {
      'public-request': 'false'
    }['public-request'],
		data: data,
		...config
	})
}

ProfileConfigService.deleteImage = function () {
	return fetch({
		url: '/config/profile/delete-profile-image',
		method: 'delete',
		headers: {
        'public-request': 'false'
        },
		
	})
}

export default ProfileConfigService