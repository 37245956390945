import fetch from 'auth/FetchInterceptor'

const JwtAuthService = {}

JwtAuthService.login = function (data) {
	return fetch({
		url: '/auth/login',
		method: 'post',
		headers: {
      'public-request': 'true'
    },
		data: data
	})
}

JwtAuthService.me = function (data) {
	return fetch({
		url: '/auth/me',
		method: 'post',
		headers: {
      'public-request': 'false'
    },
		data: data
	})
}

JwtAuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post',
		headers: {
      'public-request': 'false'
    },
	//	data: data
	})
}

JwtAuthService.resetPasswordStep1 = function (data) {
	return fetch({
		url: '/auth/reset-password-step1',
		method: 'post',
		headers: {
      'public-request': 'false'
    },
		data: data
	})
}

JwtAuthService.resetPasswordStep2 = function (data,token,email) {
	return fetch({
		url: '/auth/reset-password-step2',
		method: 'post',
		headers: {
      'public-request': 'false'
    },
		data: { password: data.password, token, email}
	})
}



JwtAuthService.signUp = function (data) {
	return fetch({
		url: '/auth/signup',
		method: 'post',
		headers: {
      'public-request': 'true'
    },
		data: data
	})
}

export default JwtAuthService