import {
  HddOutlined,
  CodeOutlined,
  TeamOutlined,
  AppstoreOutlined,
  ShopOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'Reportes',
  path: `${APP_PREFIX_PATH}/reports`,
  title: 'Reportes',
  icon: HddOutlined,
  breadcrumb: false,
  permission: null,
  submenu: []
}
];

const adminNavTree = [
  {
    key: 'Administrador',
    path: `${APP_PREFIX_PATH}/admin`,
    title: 'Administrador',
    icon: CodeOutlined,
    breadcrumb: false,
    permission: null,
    submenu: [
      {
        key: 'Visualizaciones',
        path: `${APP_PREFIX_PATH}/admin/visualizaciones`,
        title: 'Visualizaciones',
        icon: EyeOutlined,
        breadcrumb: false,
        permission: 'show visualizaciones',
        submenu: []
      },
      {
        key: 'Categorias',
        path: `${APP_PREFIX_PATH}/admin/categories`,
        title: 'Categorias',
        icon: AppstoreOutlined,
        breadcrumb: false,
        permission: 'show categories',
        submenu: []
      },
      {
        key: 'Distribuidores',
        path: `${APP_PREFIX_PATH}/admin/distribuidores`,
        title: 'Distribuidores',
        icon: ShopOutlined,
        breadcrumb: false,
        permission: 'show distribuidores',
        submenu: []
      },
      {
        key: 'Usuarios',
        path: `${APP_PREFIX_PATH}/admin/users`,
        title: 'Usuarios',
        icon: TeamOutlined,
        breadcrumb: false,
        permission: 'show users',
        submenu: []
      }
    ]
  }
]
const navigationConfig = [
  ...dashBoardNavTree,
  ...adminNavTree
]

export default navigationConfig;
