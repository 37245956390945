import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {CHANGE_PASSWORD,UPDATE_PROFILE} from '../constants/ProfileConfig';

import {
	showMessage,
	showError,
	setLoading

} from "../actions/ProfileConfig";

import {
	userSuccess
} from "../actions/Auth";

import ProfileConfigService from 'services/ProfileConfigService'

export function* changePassword() {
  yield takeEvery(CHANGE_PASSWORD, function* ({payload}) {	  	
		try {
			const response = yield call(ProfileConfigService.changePassword, payload);
			yield put(showMessage(response.message));
			yield put(setLoading(0));
		
		} catch (err) {
			yield put(showError(err.errors));
			yield put(setLoading(0));	
		}
	
	});
}

export function* updateProfile() {
	yield takeEvery(UPDATE_PROFILE, function* ({payload}) {	  	
		  try {
			  const response = yield call(ProfileConfigService.updateProfile, payload);
			  yield put(userSuccess(response.user));
			  yield put(showMessage(response.message));
			  yield put(setLoading(0));
			 
		  
		  } catch (err) {
			  yield put(showError(err.errors));
			  yield put(setLoading(0));	
		  }
	  
	  });
  }
  


export default function* rootSaga() {
  yield all([
		fork(changePassword),
		fork(updateProfile),

  ]);
}
