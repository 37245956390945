import React from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'
import { onMobileNavToggle } from "redux/actions/Theme";
import { 
  RightCircleOutlined 
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;



const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const isAlow = (item,user) =>{
  
  var tieneSubmenu = item.submenu.length >0;
  var tienePermiso=false;
  
  if(tieneSubmenu){
        item.submenu.forEach(item2 =>{
          if(isAlow(item2,user)) {
            tienePermiso = true
          }
        });
  }else{
    if(item.permission===null){
           tienePermiso =  true;
    }else{
          user.role_permissions.permissions.forEach(userPermision =>{  
                if(userPermision.name=== item.permission){
                  tienePermiso = true;
                }

          });   
    }

  }
  return tienePermiso
}

const SideNavContent = (props) => {

	const {menuItems, sideNavTheme, routeInfo, hideGroupTitle,  onMobileNavToggle,user } = props;
	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false)
		}
	}
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {menuItems.map((menu) =>
        menu.submenu.length > 0 ? (
          isAlow(menu,user)?   
            <Menu.ItemGroup
              key={menu.key}
              title={ menu.title}
            >
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  isAlow(subMenuFirst,user)?  
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={subMenuFirst.title}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      isAlow(subMenuSecond,user)?  
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>
                          { subMenuSecond.title}
                        </span>
                        <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                      </Menu.Item>:null
                    ))}
                  </SubMenu>:null
                ) : (
                  isAlow(subMenuFirst,user)?  
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                    <span>{subMenuFirst.title}</span>
                    <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                  </Menu.Item>:null
                )
              )}
            </Menu.ItemGroup>:null
        ) : (
          isAlow(menu,user)?    
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{menu?.title}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>:null
        )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { menuItems, topNavColor,  user } = props;

  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {menuItems.map((menu) =>
        menu.submenu.length > 0 ? (
          isAlow(menu,user)? 
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{ menu.title }</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                isAlow(subMenuFirst,user)? 
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={ subMenuFirst.title}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                   isAlow(subMenuSecond,user)?
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        { subMenuSecond.title}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>:null
                  ))}
                </SubMenu>:null
              ) :
              isAlow(subMenuFirst,user)?
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{subMenuFirst.title}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>:null
              
            )}
          </SubMenu>:null
        ) : (
          isAlow(menu,user)?
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{ menu?.title}</span>
              {menu.path ? <Link to={menu.path} /> : null}
            </Menu.Item>:null
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {

  var categories=[];
  props.categories.map((categorie)=>{
    if(categorie.enable){
      categories.push({
        key: categorie.id,
        path: `${APP_PREFIX_PATH}/cat/`+categorie.id+"/"+categorie.name,
        title: categorie.name,
        icon: RightCircleOutlined,
        breadcrumb: false,
        permission:null,
        submenu: []
      })
    }
    return "";
  });

  

  
    var menuItems =  [
      ...categories,
      ...navigationConfig
    ]
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent menuItems={menuItems}  {...props} />
  ) : (
    <TopNavContent  menuItems={menuItems}  {...props} />
  );
};

const mapStateToProps = ({ theme,auth }) => {
  const { sideNavTheme, topNavColor } = theme;
  const { user,categories } = auth;
  return { sideNavTheme, topNavColor,user,categories };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
