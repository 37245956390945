import fetch from 'auth/FetchInterceptor'
const ReportService = {}

ReportService.index = function (data) {
   return fetch({
        url: '/admin/reports',
        method: 'get',
        headers: {
          'public-request': 'false'
        },
        params:data});       
}

ReportService.delete = function (reportId) {
  return fetch({
       url: '/admin/reports/'+reportId,
       method: 'delete',
       headers: {
         'public-request': 'false'
       }});     
}

ReportService.update = function (reportId,data) {
	return fetch({
		url: '/admin/reports/'+reportId,
		method: 'put',
		headers: {
      'public-request': 'false'
    },
		data: data
	})
}

ReportService.create = function (categoryId,data) {
	return fetch({
		url: '/admin/reports',
		method: 'post',
		headers: {
      'public-request': 'false'
    },
		data: {'category_id':categoryId,...data}
	})
}

ReportService.uploadFile = function (config,reportId,data) {
	return fetch({
		url: '/admin/reports/uploadFile/'+reportId,
		method: 'post',
		headers: {
      'public-request': 'false'
    }['public-request'],
		data: data,
		...config
	})
}

ReportService.deleteFile = function (reportId,fileId) {
  return fetch({
		url: '/admin/reports/'+reportId+"/uploadFile/"+fileId,
		method: 'delete',
		headers: {
      'public-request': 'false'
    },
	
	})
}

export default ReportService