import { all } from 'redux-saga/effects';
import Auth from './Auth';
import ProfileConfig from './ProfileConfig';
import Admin from './admin';
import Reports from './Reports';
export default function* rootSaga(getState) {
  yield all([
    Auth(),
    ProfileConfig(),
    Admin(),
    Reports()
  ]);
}
