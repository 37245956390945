import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { notification } from 'antd';
import {
    FETCHDATA,
    CATEGORYDELETE,
    CATEGORYUPDATE,
    CATEGORYCREATE,
    RELOADCURRENTCATEGORYUPDATE
} from '../../constants/admin/Categories';

import {
    setLoading,
    setData,
    reloadTable,
    setModalFormLoading,
    setShowUpdateCategoryModal,
    setShowCreateCategoryModal,
    setFormError,
    setCurrentCategoryUpdate
} from "../../actions/admin/Categories";

import CategoryService from 'services/admin/CategoryService'


export function* fetchDataa() {
    yield takeEvery(FETCHDATA, function* ({payload}) {
       
         yield put(setLoading(true));
         const data = yield call(CategoryService.index, payload);

         var dataTosave = {
           data : data.data,
           pagination : {
             current: data.current_page,
             total : data.total,
             pageSize: data.per_page,
             showSizeChanger : false

           }
         }



        yield put(setData(dataTosave));
        yield put(setLoading(false));
      });

}

export function* categoryDelete() {
  yield takeEvery(CATEGORYDELETE, function* ({categoryId}) {



       const data = yield call(CategoryService.delete, categoryId);
       if(data.status){
          notification.success({message:data.message});
          yield put(reloadTable());
       }else{
          notification.error({message:data.message});
        }


     // yield put(setData(dataTosave));
    //  yield put(setLoading(false));
    });

}

export function* categoryUpdate() {
  yield takeEvery(CATEGORYUPDATE, function* ({payload,categoryId}) {

     try{
        const data = yield call(CategoryService.update,categoryId,payload);
        if(data.status){
            notification.success({message:data.message});
            yield put(setModalFormLoading(false));
            yield put(setShowUpdateCategoryModal(false));
            yield put(reloadTable());
        }else{
            notification.error({message:data.message});
          }
      } catch (err) {
           yield put(setFormError(err.errors));
           yield put(setModalFormLoading(false));
        }

     // yield put(setData(dataTosave));
    //  yield put(setLoading(false));
    });

}


export function* categoryCreate() {
  yield takeEvery(CATEGORYCREATE, function* ({payload,categoryId}) {	  	
    
     try{
        const data = yield call(CategoryService.create,payload);
        if(data.status){
            notification.success({message:data.message});
            yield put(setModalFormLoading(false));
            yield put(setShowCreateCategoryModal(false));
            yield put(reloadTable());     
        }else{
            notification.error({message:data.message});
          }
      } catch (err) {
           yield put(setFormError(err.errors));
           yield put(setModalFormLoading(false));
        }
    
     // yield put(setData(dataTosave));
    //  yield put(setLoading(false));     
    });

}


export function* reloadCurrentUserUpdate() {
  yield takeEvery(RELOADCURRENTCATEGORYUPDATE, function* ({id}) {	  	
       const data = yield call(CategoryService.getCategory, id);
       yield put(setCurrentCategoryUpdate(data));
     
    });

}

export default function* rootSaga() {
  yield all([
      fork(fetchDataa),
      fork(categoryDelete),
      fork(categoryUpdate),
      fork(categoryCreate),
      fork(reloadCurrentUserUpdate),
  ]);
};
