import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  RESET_PASSWORD_STEP1,
  RESET_PASSWORD_STEP2,
  RESET_PASSWORD_SUCCESS,
  USER_SUCCESS,
  GETUSER,
  GETCATEGORIES,
  SETCATEGORIES,

} from '../constants/Auth';

export const signIn = (user,redirect) => {
  return {
    type: SIGNIN,
    payload: user,
    redirect
  }
};

export const getUser = () => {
  return {
    type: GETUSER,
  };
};


export const getCategories = () => {

  return {
    type: GETCATEGORIES,
  };
};


export const authenticated = (token,user,redirect) => {
  return {
    type: AUTHENTICATED,
    token,
    user,
    redirect
  }
};

export const signOut = () => {
  return {
    type: SIGNOUT
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  }
};


export const setCategories = (categories) => {
  return {
  
    type: SETCATEGORIES,
    categories,
  }
};

export const userSuccess = (user) => {
  return {
  
    type: USER_SUCCESS,
    user: user,
  }
};
export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token
  };
};

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE
  };
};

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token
  };
};

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK
  };
};

export const signInWithFacebookAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token
  };
};

export const showAuthMessage = (message,messageType=false) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
    messageType
  };
};




export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const resetPasswordStep1 = (data) => {
  return {
    type: RESET_PASSWORD_STEP1,
    payload: data
  };
};

export const resetPasswordStep2 = (data,token,email) => {
  return {
    type: RESET_PASSWORD_STEP2,
    payload: data,
    token: token,
    email: email,
  };
};


export const resetPasswordSuccess = (message) => {
 
  return {
    type: RESET_PASSWORD_SUCCESS,
    message,
   
  };
};
