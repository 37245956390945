import {
    SETLOADING,
    SETDATA,
    FETCHDATA,
    SETKEYWORDS,
    DISTRIBUIDORDELETE,
    RELOADTABLE,
    SETTABLESTATE,
    SETSHOWUPDATEDISTRIBUIDORMODAL,
    SETCURRENTDISTRIBUIDORUPDATE,
    DISTRIBUIDORUPDATE,
    SETMODALFORMLOADING,
    SETFORMERROR,
    SETSHOWCREATEDISTRIBUIDORMODAL,
    RESETFORM,
    DISTRIBUIDORCREATE
  } from '../../constants/admin/Distribuidores';

  export const setCurrentDistribuidorUpdate= (distribuidor) => {
    return {
      type: SETCURRENTDISTRIBUIDORUPDATE,
      distribuidor
  
    }
  };

  
  export const resetForm= () => {
    return {
      type: RESETFORM,
  
    }
  };

  export const setShowUpdateDistribuidorModal= (show) => {
    return {
      type: SETSHOWUPDATEDISTRIBUIDORMODAL,
      show
  
    }
  };

  export const setShowCreateDistribuidorModal= (show) => {
    return {
      type: SETSHOWCREATEDISTRIBUIDORMODAL,
      show
  
    }
  };

  export const setFormError = (formError) => {
    return {
      type: SETFORMERROR,
      formError
  
    }
  };

  





  export const setLoading = (loading) => {
    return {
      type: SETLOADING,
      loading
  
    }
  };
  export const setKeywords = (keywords) => {
    return {
      type: SETKEYWORDS,
      keywords
  
    }
  };

  export const setData = (data) => {
    return {
      type: SETDATA,
      data
  
    }
  };
  export const setModalFormLoading = (modalFormLoading) => {
    return {
      type: SETMODALFORMLOADING,
      modalFormLoading

  
    }
  };
  export const fetchData = (payload) => { 
    return {
      type: FETCHDATA,
      payload
    }
  };

  export const reloadTable = () => { 
     
    return {
      type: RELOADTABLE,
      
    }
  };


  export const distribuidorDelete = (distribuidorId) => { 
     
    return {
      type: DISTRIBUIDORDELETE,
      distribuidorId
    }
  };


  export const distribuidorUpdate = (distribuidorId,payload) => { 
     
    return {
      type: DISTRIBUIDORUPDATE,
      payload,
      distribuidorId
    }
  };

  export const distribuidorCreate = (payload) => { 
     
    return {
      type: DISTRIBUIDORCREATE,
      payload,
      
    }
  };
  
  export const setTableState = (pagination,filters,sorter)=>{
    return {
      type: SETTABLESTATE,
      pagination,
      filters,
      sorter,
    }

  }