import {
	AUTH_TOKEN,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  RESET_PASSWORD_SUCCESS,
  USER_SUCCESS,
  SETCATEGORIES
} from '../constants/Auth';




const initState = {
  loading: false,
  message: '',
  messageType: false,
  showMessage: false,
  redirect: '',
  user: null,
  categories: null,
  token: localStorage.getItem(AUTH_TOKEN),
}

const auth = (state = initState, action) => {

	switch (action.type) {
		case AUTHENTICATED:
			return {
				...state,
				loading: false,
				redirect:action.redirect==null?'/':action.redirect,
				token: action.token,
				user: action.user
			}
		case SHOW_AUTH_MESSAGE: 
			return {
				...state,
				message: action.message,
				messageType: action.messageType,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				redirect: '/',
				loading: false,
				user:null
			}
		}
		case SIGNUP_SUCCESS: {
			return {
			  ...state,
			  loading: false,
			  token: action.token
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case RESET_PASSWORD_SUCCESS: {	       
			return {
				...state,
				message: action.message,
				messageType: true,
				showMessage: true,
		    	loading: false,
				token: null,
				redirect: '/auth/login',

			}
		}
		case USER_SUCCESS: {	       
			return {
				...state,	
				user: action.user,
				loading: false
			}
		}
		case SETCATEGORIES: {	       
			return {
				...state,	
				categories: action.categories,
			
			}
		}
		default:
			return state;
	}
}

export default auth