import {
    SETLOADING,
    SETDATA,
	SETKEYWORDS,
   RELOADTABLE,
   FETCHDATA,
   SETTABLESTATE,
   SETSHOWUPDATEDISTRIBUIDORMODAL,
   SETCURRENTDISTRIBUIDORUPDATE,
   SETMODALFORMLOADING,
   SETFORMERROR,
   SETSHOWCREATEDISTRIBUIDORMODAL,
   RESETFORM,
   
} from '../../constants/admin/Distribuidores';


const initState = {
  loading: false,
  data: [],
  keywords:"",
  reloadTable:false,
  tableState:{ pagination:null,	filters:null,sorter:null,},
  currentDistribuidorUpdate:null ,
  showUpdatDistribuidorModal:false,
  showCreateDistribuidorModal:false,
  modalFormLoading:false,
  formError:null,

}

const distribuidores = (state = initState, action) => {
	
	switch (action.type) {
		case SETSHOWUPDATEDISTRIBUIDORMODAL:
			return {
				...state,
				showUpdateDistribuidorModal:action.show,

			}
		case RESETFORM:
	    	return {
				...state,
				formError:null,
				modalFormLoading:false,
				currentDistribuidorUpdate:null ,

			}
		case SETSHOWCREATEDISTRIBUIDORMODAL:
				return {
					...state,
					showCreateDistribuidorModal:action.show,
	
				}
	   case SETFORMERROR:
			return {
				...state,
				formError:action.formError,

			}
    	case SETMODALFORMLOADING:
				return {
					...state,
					modalFormLoading:action.modalFormLoading,
	
				}
	
	    case SETCURRENTDISTRIBUIDORUPDATE:
			return {
				...state,
				currentDistribuidorUpdate: action.distribuidor,
				showUpdateDistribuidorModal:true
			}
		case SETLOADING:
			return {
				...state,
				loading: action.loading
			}
		case RELOADTABLE:
			return {
				...state,
				reloadTable: true
			}
		case SETTABLESTATE:
			return {
				...state,
				tableState: { pagination: action.pagination,filters:action.filters,sorter:action.sorter}
				
			}
		case FETCHDATA:
			return {
				...state,
				reloadTable: false
			}
    	case SETDATA:
			return {
				...state,
				data: action.data
			}
			case SETKEYWORDS:
				return {
					...state,
					keywords: action.keywords
				}
		default:
			return state;
	}
}

export default distribuidores