import fetch from 'auth/FetchInterceptor'
const DistribuidorService = {}

DistribuidorService.index = function (data) {
   return fetch({
        url: '/admin/distribuidores',
        method: 'get',
        headers: {
          'public-request': 'false'
        },
        params:data});       
}

DistribuidorService.delete = function (distribuidorId) {
  return fetch({
       url: '/admin/distribuidores/'+distribuidorId,
       method: 'delete',
       headers: {
         'public-request': 'false'
       }});     
}

DistribuidorService.update = function (distribuidorId,data) {
	return fetch({
		url: '/admin/distribuidores/'+distribuidorId,
		method: 'put',
		headers: {
      'public-request': 'false'
    },
		data: data
	})
}

DistribuidorService.create = function (data) {
	return fetch({
		url: '/admin/distribuidores',
		method: 'post',
		headers: {
      'public-request': 'false'
    },
		data: data
	})
}

export default DistribuidorService