export const SETLOADING = 'SETLOADING_USERS'
export const SETDATA = 'SETDATA_USERS'
export const SETKEYWORDS = 'SETKEYWORDS_USERS'
export const FETCHDATA = 'FETCHDATA_USERS'
export const USERDELETE = 'USERDELETE_USERS'
export const USERUPDATE = 'USERUPDATE_USERS'
export const USERCREATE = 'USERCREATE_USERS'
export const RELOADTABLE = 'RELOADTABLE_USERS'
export const SETTABLESTATE = 'SETTABLESTATE_USERS'

export const SETMODALFORMLOADING = 'SETMODALFORMLOADING_USERS'
export const SETFORMERROR = 'SETFORMERROR_USERS'
export const RESETFORM = 'RESETFORM_USERS'

export const SETSHOWUPDATEUSERMODAL = 'SETSHOWUPDATEUSERMODAL_USERS'
export const SETSHOWCREATEUSERMODAL = 'SETSHOWCREATEUSERMODAL_USERS'
export const SETCURRENTUSERUPDATE = 'SETCURRENTUSERUPDATE_USERS'


export const SETROLES = 'SETROLES_USERS'
export const FETCHROLES = 'FETCHROLES_USERS'

export const SETDISTRIBUIDORES = 'SETDISTRIBUIDORES_USERS'
export const FETCHDISTRIBUIDORES = 'FETCHDISTRIBUIDORES_USERS'
export const SETDISTRIBUIDORESSHOWSELECT = 'SETDISTRIBUIDORESSHOWSELECT_USERS'
export const RELOADCURRENTUSERUPDATE = 'RELOADCURRENTUSERUPDATE_USERS'
