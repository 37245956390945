import fetch from 'auth/FetchInterceptor'

const UserService = {}


UserService.index = function (data) {

   return fetch({
        url: '/admin/users',
        method: 'get',
        headers: {
          'public-request': 'false'
        },
        params:data});
        
}


UserService.getUser = function (userId) {

  return fetch({
       url: '/admin/users/'+userId,
       method: 'get',
       headers: {
         'public-request': 'false'
       },
     });
       
}


UserService.delete = function (userId) {

  return fetch({
       url: '/admin/users/'+userId,
       method: 'delete',
       headers: {
         'public-request': 'false'
       }});
       
}


UserService.update = function (userId,data) {
	return fetch({
		url: '/admin/users/'+userId,
		method: 'put',
		headers: {
      'public-request': 'false'
    },
		data: data
	})
}


UserService.updateImage = function (config,data,userId) {
 

  return fetch({
		url: '/admin/users/updateProfileImage/'+userId,
		method: 'post',
		headers: {
      'public-request': 'false'
    }['public-request'],
		data: data,
		...config
	})
}

UserService.deleteImage = function (userId) {
  return fetch({
		url: '/admin/users/updateProfileImage/'+userId,
		method: 'delete',
		headers: {
      'public-request': 'false'
    },
	
	})
}




UserService.create = function (data) {
	return fetch({
		url: '/admin/users',
		method: 'post',
		headers: {
      'public-request': 'false'
    },
		data: data
	})
}



export default UserService