import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { notification } from 'antd';
import {
    FETCHDATA,
    DISTRIBUIDORDELETE,
    DISTRIBUIDORUPDATE,
    DISTRIBUIDORCREATE
} from '../../constants/admin/Distribuidores';

import {
    setLoading,
    setData,
    reloadTable,
    setModalFormLoading,
    setShowUpdateDistribuidorModal,
    setShowCreateDistribuidorModal,
    setFormError,

} from "../../actions/admin/Distribuidores";

import DistribuidorService from 'services/admin/DistribuidorService'


export function* fetchDataa() {
    yield takeEvery(FETCHDATA, function* ({payload}) {
       
         yield put(setLoading(true));
         const data = yield call(DistribuidorService.index, payload);
         
         var dataTosave = {
           data : data.data,
           pagination : {
             current: data.current_page,
             total : data.total,
             pageSize: data.per_page,
             showSizeChanger : false

           }
         }



        yield put(setData(dataTosave));
        yield put(setLoading(false));
      });

}

export function* distribuidorDelete() {
  yield takeEvery(DISTRIBUIDORDELETE, function* ({distribuidorId}) {



       const data = yield call(DistribuidorService.delete, distribuidorId);
       if(data.status){
          notification.success({message:data.message});
          yield put(reloadTable());
       }else{
          notification.error({message:data.message});
        }


     // yield put(setData(dataTosave));
    //  yield put(setLoading(false));
    });

}

export function* distribuidorUpdate() {
  yield takeEvery(DISTRIBUIDORUPDATE, function* ({payload,distribuidorId}) {

     try{
        const data = yield call(DistribuidorService.update,distribuidorId,payload);
        if(data.status){
            notification.success({message:data.message});
            yield put(setModalFormLoading(false));
            yield put(setShowUpdateDistribuidorModal(false));
            yield put(reloadTable());
        }else{
            notification.error({message:data.message});
          }
      } catch (err) {
           yield put(setFormError(err.errors));
           yield put(setModalFormLoading(false));
        }

     // yield put(setData(dataTosave));
    //  yield put(setLoading(false));
    });

}


export function* distribuidorCreate() {
  yield takeEvery(DISTRIBUIDORCREATE, function* ({payload}) {	  	
    
     try{
        const data = yield call(DistribuidorService.create,payload);
        if(data.status){
            notification.success({message:data.message});
            yield put(setModalFormLoading(false));
            yield put(setShowCreateDistribuidorModal(false));
            yield put(reloadTable());     
        }else{
            notification.error({message:data.message});
          }
      } catch (err) {
           yield put(setFormError(err.errors));
           yield put(setModalFormLoading(false));
        }
    
     // yield put(setData(dataTosave));
    //  yield put(setLoading(false));     
    });

}



export default function* rootSaga() {
  yield all([
      fork(fetchDataa),
      fork(distribuidorDelete),
      fork(distribuidorUpdate),
      fork(distribuidorCreate),

  ]);
};
