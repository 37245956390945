export const SETLOADING = 'SETLOADING_REPORTS'
export const SETDATA = 'SETDATA_REPORTS'
export const SETKEYWORDS = 'SETKEYWORDS_REPORTS'
export const FETCHDATA = 'FETCHDATA_REPORTS'
export const REPORTDELETE = 'REPORTDELETE_REPORTS'
export const REPORTUPDATE = 'REPORTUPDATE_REPORTS'
export const REPORTCREATE = 'REPORTCREATE_REPORTS'
export const RELOADTABLE = 'RELOADTABLE_REPORTS'
export const SETTABLESTATE = 'SETTABLESTATE_REPORTS'

export const SETMODALFORMLOADING = 'SETMODALFORMLOADING_REPORTS'
export const SETFORMERROR = 'SETFORMERROR_REPORTS'
export const RESETFORM = 'RESETFORM_REPORTS'

export const SETSHOWUPDATEREPORTMODAL = 'SETSHOWUPDATEREPORTMODAL_REPORTS'
export const SETSHOWCREATEREPORTMODAL = 'SETSHOWCREATEREPORTMODAL_REPORTS'
export const SETCURRENTREPORTUPDATE = 'SETCURRENTREPORTUPDATE_REPORTS'

export const SETDISTRIBUIDORES = 'SETDISTRIBUIDORES_DISTRIBUIDORES'
export const FETCHDISTRIBUIDORES = 'FETCHDISTRIBUIDORES_DISTRIBUIDORES'
export const SETCATEGORIES = 'SETCATEGORIES_DISTRIBUIDORES'
export const FETCHCATEGORIES = 'FETCHCATEGORIES_DISTRIBUIDORES'


export const SETSELECTEDCATEGORY = 'SETSELECTEDCATEGORY_DISTRIBUIDORES'
export const SETSELECTEDDISTRIBUIDOR = 'SETSELECTEDDISTRIBUIDOR_DISTRIBUIDORES'
export const SETSTARTDATE = 'SETSTARTDATE_DISTRIBUIDORES'
export const SETENDDATE = 'SETENDDATE_DISTRIBUIDORES'


export const SETSHOWREPORTMODAL = 'SETSHOWREPORTMODAL_REPORTS'
export const SETCURRENTREPORTSHOW = 'SETCURRENTREPORTSHOW_REPORTS'

export const SETSELECTEDROWS = 'SETSELECTEDROWS_REPORTS'