import { all } from 'redux-saga/effects';
import Users from './Users';
import Categories from './Categories';
import Distribuidores from './Distribuidores';
export default function* rootSaga() {
    yield all([
        Users(),
        Categories(),
        Distribuidores()
    ]);
    
  }
  